<template>
  <v-container fluid>
    <v-card elevation="2">
      <v-card-text>
        <v-form ref="formProductor">
          <h1><v-icon class="iconTitle">mdi-account-group</v-icon> Ficha de productor</h1><br>
          <v-layout wrap>
            <v-flex xs11>
              <v-autocomplete
                v-model="selectClient"
                :items="this.$store.state.productor"
                item-text="nombre"
                item-value="id"
                flat
                hide-no-data
                label="Buscar productor"
                prepend-inner-icon="mdi-account-search"
                solo-inverted
                @change="getClient();"
              ></v-autocomplete>
            </v-flex>
            <v-flex xs1>
              <v-btn v-on:click="clear" text class="btnMini">
                <v-icon large>mdi-plus</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs4>
              <v-text-field
                label="Nombre"
                outlined
                v-model="nombre"
                :rules="rules.required"
              ></v-text-field>
            </v-flex>
            <v-flex xs2>
              <v-text-field
                label="Código"
                outlined
                v-model="codigo"
              ></v-text-field>
            </v-flex>
            <v-flex xs2>
              <v-text-field
                label="CIF"
                outlined
                v-model="cif"
              ></v-text-field>
            </v-flex>
            <v-flex xs2>
              <v-text-field
                label="Teléfono"
                outlined
                v-model="telefono"
              ></v-text-field>
            </v-flex>
            <v-flex xs2>
              <v-text-field
                label="Email"
                outlined
                v-model="email"
                :rules="rules.required"
                :disabled="isDisabledLogin"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs4>
              <v-text-field
                label="Dirección"
                outlined
                v-model="direccion"
              ></v-text-field>
            </v-flex>
            <v-flex xs2>
              <v-text-field
                label="Código postal"
                outlined
                v-model="cp"
              ></v-text-field>
            </v-flex>
            <v-flex xs3>
              <v-text-field
                label="Localidad"
                outlined
                v-model="localidad"
              ></v-text-field>
            </v-flex>
            <v-flex xs3>
              <v-text-field
                label="Provincia"
                outlined
                v-model="provincia"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <h2 style="margin: 10px 0">Datos de acceso a oliCloud</h2>
          <v-layout>
            <v-flex xs3>
              <v-text-field
                label="Usuario"
                outlined
                v-model="email"
                :rules="rules.required"
                :disabled="isDisabledLogin"
              ></v-text-field>
            </v-flex>
            <v-flex xs3>
              <v-text-field
                label="Contraseña"
                outlined
                v-model="contrasena"
                :rules="rules.required"
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPass ? 'text' : 'password'"
                @click:append="showPass = !showPass"
                :disabled="isDisabledLogin"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn class="btnCustom save" :loading="loading" @click="saveClient"><v-icon class="btnIcon">mdi-content-save</v-icon> Guardar</v-btn>
        <v-btn class="btnCustom delete" :disabled="isDisabledButton" @click="deleteClient"><v-icon class="btnIcon">mdi-delete</v-icon> Eliminar</v-btn>
        <v-btn class="btnCustom" :disabled="isDisabledButton" @click="newLand"><v-icon class="btnIcon">mdi-tree</v-icon> Nueva finca</v-btn>
      </v-card-actions>
    </v-card>
    <v-layout style="margin-top: 20px">
      <v-flex xs12>
        <v-data-table
          :headers="headers"
          :items="fincas"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              {{ item.comentario }}
            </td>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editarFinca(item)">
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <showDialog ref="dialog" />
    <showMessage ref="snackbar" />
  </v-container>
</template>

<script>
import {BASE_URL} from '../components/constants.js'
import showMessage from '../components/Messages.vue'
import showDialog from '../components/Dialog.vue'
import genericReload from '../components/genericReload';
export default {
  name: 'Productores',
  data () {
    return {
      rules: {
        required: [val => (val || '').length > 0 || 'Este campo es obligatorio'],
      },
      loading: false,
      showPass: true,
      selectClient: null,
      nombre: '',
      cif: '',
      codigo: '',
      telefono: '',
      email: '',
      direccion: '',
      cp: '',
      localidad: '',
      provincia: '',
      contrasena: '',
      isDisabledLogin: false,
      isDisabledButton: true,
      headers: [
        {
          text: 'Identificador',
          align: 'start',
          sortable: false,
          value: 'identificador',
        },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Superficie (Ha)', value: 'superficie' },
        { text: 'Plantas', value: 'plantas' },
        { text: 'Variedad', value: 'variedad' },
        { text: 'Polígono', value: 'poligono' },
        { text: 'Parcela', value: 'parcela' },
        { text: 'Recinto', value: 'recinto' },
        { text: 'Localidad', value: 'localidad' },
        { text: 'Provincia', value: 'provincia' },
        { text: '', value: 'actions', sortable: false },
      ],
      fincas: [],
    }
  },
  components: {
    showMessage,
    showDialog,
  },
  mounted(){
  },
  methods:{
    async saveClient(){
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+this.$store.state.auth
      };
      let response, data;
      this.loading = true;
      if(this.selectClient == null){
        data = {
          nombre: this.nombre,
          NIF: this.cif,
          codigo: this.codigo,
          email: this.email,
          telefono: this.telefono,
          direccion: this.direccion,
          localidad: this.localidad,
          provincia: this.provincia,
          cp: this.cp,
          password: this.contrasena,
          fechaExpiracion: "2040-12-31",
        };
        response = await fetch(
          `${BASE_URL}/productor`,
          {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data),
          }
        );
      }else{
        data = {
          nombre: this.nombre,
          NIF: this.cif,
          codigo: this.codigo,
          telefono: this.telefono,
          direccion: this.direccion,
          localidad: this.localidad,
          provincia: this.provincia,
          cp: this.cp,
        };
        response = await fetch(
          `${BASE_URL}/productor/${this.selectClient}`,
          {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(data),
          }
        );
      }
      switch (response.status) {
        case 200:
          this.$refs.snackbar.setNew(true, "Datos guardados correctamente", "Ok", "green");
          this.clear();
          await this.$store.dispatch('getProductor');
          this.loading = false;
          break;
        default:
          this.$refs.snackbar.setNew(true, "ERROR. Datos no guardados", "Cerrar", "red");
          this.loading = false;
          break;
      }
    },
    async getClient(){
      if(this.selectClient != null){
        const response = await genericReload(this.$store.state.auth, `productor/${this.selectClient}`);
        if (response.status === 200) {
          const json = await response.json();
          this.nombre = json.body[0].nombre;
          this.cif = json.body[0].NIF;
          this.codigo = json.body[0].codigo;
          this.email = json.body[0].email;
          this.telefono = json.body[0].telefono;
          this.localidad = json.body[0].localidad;
          this.direccion = json.body[0].direccion;
          this.provincia = json.body[0].provincia;
          this.cp = json.body[0].cp;
          this.isDisabledLogin = true;
          this.isDisabledButton = false;
          this.readFinca();
        }else{
          this.$refs.snackbar.setNew(true, "ERROR. No se han podido cargar los datos de la almazara", "Cerrar", "red");
        }
      }
    },
    clear(){
      this.$refs.formProductor?.reset();
      this.isDisabledLogin = false;
      this.isDisabledButton = true;
      this.selectClient = null;
      this.fincas = [];
    },
    deleteClient(){
      this.$refs.dialog.setNew("Eliminar productor", "¿Estás seguro de querer eliminar este productor? En caso de aceptar se procederá a eliminar este productor de su base de datos y nos pondremos en contacto con el productor para estudiar el borrado de todos sus datos de forma definitiva.", 0).then(async(confirm) => {
        if(confirm.response){
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+this.$store.state.auth
          };

          const response = await fetch(
            `${BASE_URL}/productor/${this.selectClient}`,
            {
              method: 'DELETE',
              headers: headers,
            }
          );
          switch (response.status) {
            case 200:
              this.$refs.snackbar.setNew(true, "Productor eliminado correctamente", "Ok", "green");
              this.clear();
              await this.$store.dispatch('getProductor');
              break;
            default:
              this.$refs.snackbar.setNew(true, "ERROR. Productor no eliminado", "Cerrar", "red");
              break;
          }
        }
      });
    },
    async newLand(){
      this.$refs.dialog.setNew("Nueva finca", "", "newLand").then(async(confirm) => {
        if(confirm.response){
          const data = {
            idProductor: this.selectClient, 
            identificadorFinca: confirm.identificador, 
            nombre: confirm.nombre, 
            superficie: confirm.superficie,
            medidaSuperficie: "HA",
            plantas: confirm.plantas,
            tipoPlanta: confirm.variedad,
            poligono: confirm.poligono,
            parcela: confirm.parcela,
            recinto: confirm.recinto,
            localidad: confirm.localidad,
            provincia: confirm.provincia,
          }
          console.log(data);
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+this.$store.state.auth
          };

          const response = await fetch(
            `${BASE_URL}/finca`,
            {
              method: 'POST',
              headers: headers,
              body: JSON.stringify(data),
            }
          );
          switch (response.status) {
            case 200:
              this.$refs.snackbar.setNew(true, "Datos guardados correctamente", "Ok", "green");
              this.readFinca();
              break;
            default:
              this.$refs.snackbar.setNew(true, "ERROR. Datos no guardados", "Cerrar", "red");
              break;
          }
        }
      });
    },
    async readFinca(){
      this.fincas = [];
      if(this.selectClient){
        const response = await genericReload(this.$store.state.auth, `finca/?idProductor=${this.selectClient}`);
        if (response.status === 200) {
          const json = await response.json();
          let pro;
          for(let i = 0; i<json.body.length; i++){
            pro = this.$store.getters.provinciaById(json.body[i].provincia);
            this.fincas.push({
              id: json.body[i].id, 
              identificador: json.body[i].identificadorFinca, 
              nombre: json.body[i].nombre, 
              superficie: json.body[i].superficie,
              plantas: json.body[i].plantas,
              variedad: json.body[i].tipoPlanta,
              poligono: json.body[i].poligono,
              parcela: json.body[i].parcela,
              recinto: json.body[i].recinto,
              localidad: json.body[i].localidad,
              provincia: pro.nm,
            });
          }
        }else{
          this.$refs.snackbar.setNew(true, "ERROR. No se han podido cargar los datos", "Cerrar", "red");
        }
      }
    },
    async editarFinca(item){
      const pro = this.$store.getters.provinciaByName(item.provincia);
      this.$refs.dialog.setNew("Editar finca", "", "editLand", [item.identificador, item.nombre, item.superficie, item.plantas, item.variedad, item.poligono, item.parcela, item.recinto, pro.id, item.localidad]).then(async(confirm) => {
        if(confirm.response){
          const data = {
            idProductor: this.selectClient, 
            identificadorFinca: confirm.identificador, 
            nombre: confirm.nombre, 
            superficie: confirm.superficie,
            plantas: confirm.plantas,
            tipoPlanta: confirm.variedad,
            poligono: confirm.poligono,
            parcela: confirm.parcela,
            recinto: confirm.recinto,
            provincia: pro.id,
            localidad: confirm.localidad,
          };
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+this.$store.state.auth
          };

          const response = await fetch(
            `${BASE_URL}/finca/${item.id}`,
            {
              method: 'PUT',
              headers: headers,
              body: JSON.stringify(data),
            }
          );
          switch (response.status) {
            case 200:
              this.$refs.snackbar.setNew(true, "Finca editada correctamente", "Ok", "green");
              this.readFinca();
              break;
            default:
              this.$refs.snackbar.setNew(true, "ERROR. Finca no editada", "Cerrar", "red");
              break;
          }
        }
      })
    },
  }
}
</script>
<style scoped>
.v-input{
  margin-right: 1em;
}
.custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>